body,
html {
  font-family: "Montserrat", sans-serif;
  display: grid;
  margin: 0;
}

.nav {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100vw;
  background: #efdcf9;
}
#basic {
  padding: 0.3rem;
  color: black;
  text-decoration: none;
  font-size: 3vh;
  background: #efdcf9;
}
.App {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
  place-items: center;
}
main {
  display: grid;
  place-items: center;
}
.img {
  background-color: aqua;
}
.output {
  text-align: center;
}

* {
  background-color: #faf9f6;
}
.country {
  background-color: aquamarine;
}
#output {
  margin: 20px;
}
.App-link {
  color: #61dafb;
}
a {
  display: inline-flex;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  transition: transform 0.5s ease;
}
a:hover {
  transform: translateY(-3px);
}
#footer:hover {
  color: magenta;
}
.Footer {
  display: flex;
  width: 100vw;
  justify-content: center;
  place-items: center;

  background: #efdcf9;
}

#footer {
  padding: 1.5rem;
  font-size: 2vh;
  text-decoration: none;
  color: black;
  background: #efdcf9;
}
